import i18n from '@/i18n';
import store from '@/store';
import { ManualAPISettings } from '@/interfaces/general/ManualAPISettings';

export function manualAPIActions(): ManualAPISettings[] {
    return [
        {
            buttonLabel: i18n.t('Ažuriraj forme proizvoda'),
            modalTitle: i18n.t('Jeste li sigurni da želite ažurirati forme proizvoda?'),
            action: async () => {
                return await store.dispatch('configurator/updateProductForms');
            },
            successMessage: i18n.t('Forme proizvoda uspješno ažurirane!'),
        },
        {
            buttonLabel: i18n.t('Uvezi forme proizvoda'),
            modalTitle: i18n.t('Jeste li sigurni da želite uvesti nove forme proizvoda?'),
            action: async () => {
                return await store.dispatch('configurator/importProductForms');
            },
            successMessage: i18n.t('Forme proizvoda uspješno uvezene!'),
        },
        {
            buttonLabel: i18n.t('Update javascript functions'),
            modalTitle: i18n.t('Are you sure you want to import new javascript functions?'),
            action: async () => {
                return await store.dispatch('configurator/importJavascriptFunctions');
            },
            successMessage: i18n.t('Javascript functions updated successfully!'),
        },
        {
            buttonLabel: i18n.t('Uvezi materijale'),
            modalTitle: i18n.t('Jeste li sigurni da želite uvesti nove materijale?'),
            action: async () => {
                return await store.dispatch('configurator/importMaterials');
            },
            successMessage: i18n.t('Materijali uspješno uvezeni!'),
        },
        {
            buttonLabel: i18n.t('Uvezi liste cijena'),
            modalTitle: i18n.t('Jeste li sigurni da želite uvesti nove liste cijena?'),
            action: async () => {
                return await store.dispatch('configurator/importPriceLists');
            },
            successMessage: i18n.t('Liste cijena uspješno uvezene!'),
        },
        {
            buttonLabel: i18n.t('Uvezi vrste lista cijena'),
            modalTitle: i18n.t('Jeste li sigurni da želite uvesti nove vrste lista cijena?'),
            action: async () => {
                return await store.dispatch('configurator/importPriceListTypes');
            },
            successMessage: i18n.t('Vrste lista cijena uspješno uvezene!'),
        },
        {
            buttonLabel: i18n.t('Uvezi popuste'),
            modalTitle: i18n.t('Jeste li sigurni da želite uvesti nove popuste?'),
            action: async () => {
                return await store.dispatch('configurator/importDiscounts');
            },
            successMessage: i18n.t('Popusti uspješno uvezeni!'),
        },
        {
            buttonLabel: i18n.t('Uvezi tehničke dokumente'),
            modalTitle: i18n.t('Jeste li sigurni da želite uvesti nove tehničke dokumente?'),
            action: async () => {
                return await store.dispatch('configurator/importTechnicalDocuments');
            },
            successMessage: i18n.t('Tehnički dokumenti uspješno uvezeni!'),
            isDisabled: false,
        },
        {
            buttonLabel: i18n.t('Import colors'),
            modalTitle: i18n.t('Are you sure you want to import new colors?'),
            action: async () => {
                return await store.dispatch('configurator/importColors');
            },
            successMessage: i18n.t('Colors successfully imported!'),
            isDisabled: false,
        },
        {
            buttonLabel: i18n.t('Start importing colors'),
            modalTitle: i18n.t('Are you sure you want to start importing new colors?'),
            action: async () => {
                await store.dispatch('configurator/toggleColorImporting');
                return await store.dispatch('configurator/getColorImportingStatus');
            },
            successMessage: i18n.t('Colors import successfully started!'),
            isDisabled: false,
            type: 'colorImportStart',
        },
        {
            buttonLabel: i18n.t('Stop importing colors'),
            modalTitle: i18n.t('Are you sure you want to stop importing new colors?'),
            action: async () => {
                await store.dispatch('configurator/toggleColorImporting');
                return await store.dispatch('configurator/getColorImportingStatus');
            },
            successMessage: i18n.t('Colors import successfully stopped!'),
            isDisabled: false,
            type: 'colorImportStop',
        },
    ];
}
